/* eslint-disable max-classes-per-file */
/* eslint-env browser */

class ArrhythmiaSetting {
  constructor(arrhythmiaSetting) {
    Object.assign(this, arrhythmiaSetting);
    this.pauseDuration = arrhythmiaSetting.pauseDuration / 1000; // convert to seconds
    this.hpFilter = arrhythmiaSetting.hpFilter / 100; // convert to Hz
  }
}

/* @ngInject */
export default class ArrhythmiaSettingService {
  constructor($injector) {
    this._$injector = $injector;
    this._backendConfig = $injector.get("backendConfig");
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
  }

  /**
   * Gets all arrhythmia settings
   * @param {Object} params
   * @returns {Promise.<Object[], Object>} Promise that will be resolved with array of arrhythmia setting
   *                                       objects or rejected with response object
   *
   * @see SRS: BR-3863
   */
  getAll(params = {}) {
    const arrhythmiaSettingUrl = "/facilityArrhythmiaSettings";

    return this.httpGet(arrhythmiaSettingUrl, params).then((response) => {
      this.arrhythmiaSettings = response.data.map(
        (arrhythmiaSetting) => new ArrhythmiaSetting(arrhythmiaSetting)
      );
      return this.arrhythmiaSettings;
    });
  }

  /*
   * @see SRS: BR-3863
   */
  httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }
}
