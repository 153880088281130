/* eslint-env browser */
import moment from "moment";

/* @ngInject */
export default class Config {
  // backendConfig is defined in the node route that renders layout (routes/index.js)
  constructor(backendConfig) {
    this.apiUrl = backendConfig.apiUrl;
    this.socketUrl = window.location.host;
    this.features = backendConfig.features;
    this.adminUrl = backendConfig.adminUrl;
    this.disableDebugInfo = backendConfig.disableDebugInfo;
    this.topNavOffset = 80;
    this.ecgViewerStripLength = backendConfig.ecgViewerStripLength;
    this.refreshJwtThresholdSeconds = backendConfig.refreshJwtThresholdSeconds;

    // Converts time base, start and end times to base strip length
    const getStripLength = (strip) => {
      if (strip) {
        return Math.round(moment(strip.endTime).diff(strip.startTime) / 1000);
      }
      return this.ecgViewerStripLength;
    };

    // Converts time base, start and end times to base strip length using time base 25mm
    const getStripLengthAt25mm = (strip) => {
      this.stripLength = getStripLength(strip) * (strip.timeBase / 25);
      return this.stripLength;
    };

    this.ecgStripViewerConfig = {
      getStripLength,
      getStripLengthAt25mm,
      // The number of seconds shown in a strip at 25 mm/s
      stripLength: this.ecgViewerStripLength,
      displayElements: {
        leads: {
          I: true,
          II: true,
          III: true,
        },
      },
      inversionStatus: {
        leads: {
          I: false,
          II: false,
          III: false,
        },
      },
      type: "strip",
    };

    this.sequenceViewerConfig = {
      getStripLength,
      getStripLengthAt25mm,
      // The sequence viewer needs to know how long the strip viewer is in seconds including when its overridden by the strips actual length
      stripLength: this.ecgStripViewerConfig.stripLength,
      displayElements: {
        leads: {
          I: false,
          II: true,
          III: false,
        },
      },
      inversionStatus: {
        leads: {
          I: false,
          II: false,
          III: false,
        },
      },
      type: "sequence",
      maxSeconds: 60,
    };

    this.fullViewerConfig = {
      getStripLength,
      // The Full viewer needs to know how long the strip viewer is in seconds including when its overridden by the strips actual length
      stripLength: this.ecgStripViewerConfig.stripLength,
      displayElements: {
        leads: {
          I: false,
          II: false,
          III: false,
        },
      },
      type: "full",
    };
  }
}
