/* eslint-disable max-classes-per-file */
/* eslint-env browser */

class H3rDeviceConfiguration {
  constructor(deviceConfiguration) {
    Object.assign(this, deviceConfiguration);

    // This property is added on the frontend to identify the device type associated with the device config
    this.deviceType = "h3r";
  }
}

/* @ngInject */
export default class DeviceConfigurationService {
  constructor($injector) {
    this._$injector = $injector;
    this._backendConfig = $injector.get("backendConfig");
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this.supportedDeviceTypes = ["h3r"];
  }

  /**
   * Gets all device configurations
   * @param {Object} params
   * @returns {Promise.<Object[], Object>} Promise that will be resolved with array of device configuration
   *                                       objects or rejected with response object
   *
   * @see SRS: BR-11
   */
  getAll(params = {}) {
    const h3rDeviceTypeUrl = "/facilityDeviceConfigurations/h3r";

    return this.httpGet(h3rDeviceTypeUrl, params).then((response) => {
      const deviceConfigurationsTridentPro40 = response.data.map(
        (deviceConfig) => new H3rDeviceConfiguration(deviceConfig)
      );
      this.deviceConfigurations = deviceConfigurationsTridentPro40;
      return this.deviceConfigurations;
    });
  }

  /*
   * @see SRS: BR-11
   */
  httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }
}
