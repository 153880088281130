/* eslint-env browser */
/* eslint-disable max-classes-per-file */

class Device {
  constructor(event) {
    Object.assign(this, event);
  }
}

/* @ngInject */
export default class DeviceService {
  constructor(backendConfig, $http, Authentication) {
    this._backendConfig = backendConfig;
    this._$http = $http;
    this._Authentication = Authentication;
  }

  getDevicesAvailableForStudies(params = {}) {
    Object.assign(params, {
      forwardingUrl: "",
      operationalState: "active",
      availableForStudy: "true",
    });
    const urlQuery = this._toUrlEncoding(params);
    const url = `/devices${urlQuery}`;

    return this.httpGet(url).then(
      (response) => response.data.map((device) => new Device(device)),
      (error) => {
        throw error;
      }
    );
  }

  httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }

  _toUrlEncoding(queryObject) {
    // creates '{"operationalState":{"$ne":"terminated"}}'
    let queryString = JSON.stringify(queryObject);

    // extracts all instance of :{"$ne": into array
    const operatorStrings = queryString.match(/:{"\$.*":/);

    if (operatorStrings !== null) {
      operatorStrings.forEach((operatorString) => {
        // extracts ne
        const operatorName = operatorString.substring(4, operatorString.length - 2);

        /*
         * creates '{"operationalState"%5B%24ne%5D="terminated"}}'
         * which translates to "operationalState"[$ne]="terminated"
         * %5B = [
         * %24 = $
         * %5D = ]
         */
        queryString = queryString.replace(operatorString, `%5B%24${operatorName}%5D=`);
      });
    }
    // replacing : with =
    queryString = queryString.replace(/:/g, "=");
    // remove {, }, ", ' and their unicode counterparts
    queryString = queryString.replace(/[{}"'\u2018\u2019\u201c\u201d]/g, "");
    // replace , with &
    queryString = queryString.replace(/,/g, "&");

    return `?${queryString}`;
  }
}
