import md5 from "crypto-js/md5";

/* @ngInject */
export default class Avatars {
  constructor($injector) {
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
  }

  /**
   * Determines the avatar url (gravatar) for the user.
   * Returns null if the User does not have a Gravatar Profile
   *
   * @param {String} userEmail
   * @returns {Promise<String | null>}
   */
  getAvatarUrlIfExists(userEmail) {
    const userHash = this._getMD5Hash(userEmail);
    const avatarUrl = `https://www.gravatar.com/avatar/${userHash}`;
    return this._$http
      .get(`${avatarUrl}?d=404`)
      .then(() => avatarUrl)
      .catch(() => null); // Return null if the user does not have a gravatar profile
  }

  /**
   * @param {String} email
   * @returns {String}
   */
  _getMD5Hash(email) {
    const reAlias = /(?<=^[\w-.]+)\+.*?(?=@)/;
    const trimmedEmail = email.trim().toLowerCase().replace(reAlias, "");
    return md5(trimmedEmail);
  }
}
