/* eslint-disable max-classes-per-file */
import {DateTime} from "luxon";
import queryString from "qs";

class Action {
  constructor($injector, action) {
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this._backendConfig = $injector.get("backendConfig");

    Object.assign(this, action);
  }

  /**
   * Sends delete request to actions/:id
   * or studies/replaceDevice/:studyId for replaceDevice actions
   * @param {Boolean} isReplaceDevice
   *
   * @see SRS: BR-1238
   * @returns {Promise} Promise object represents the result of delete http request
   */
  remove() {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    let url = `${this._backendConfig.apiUrl}/actions/${this.id}`;
    if (this.name === "replaceDevice") {
      url = `${this._backendConfig.apiUrl}/studies/replaceDevice/${this.studyId}`;
    }

    return this._$http
      .delete(url, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

/* @ngInject */
export default class ActionService {
  constructor($injector, backendConfig, $http, Authentication) {
    this._$injector = $injector;
    this._backendConfig = backendConfig;
    this._$http = $http;
    this._Authentication = Authentication;
    this._features = this._backendConfig.features;

    this.allowedActionTypes = [
      {name: "endStudy", dataType: "none"},
      {name: "forceActionsFailure", dataType: "none"},
      {name: "formatDevice", dataType: "string"},
      {name: "requestEcgData", dataType: "object"},
      {name: "sendMessage", dataType: "string"},
      {name: "updateSettings", dataType: "object"},
      {name: "replaceDevice", dataType: "object"},
      {name: "convertMctToCem", dataType: "object"},
      {name: "convertMctWithFullDisclosureToCem", dataType: "object"},
      {name: "resumeStudy", dataType: "object"},
      {name: "markStudyAsFailed", dataType: "object"},
    ];

    this.userFriendlyTypeNames = {
      requestErrorLogs: "Request Error Logs",
      requestEcgData: "Request ECG Data",
      sendMessage: "Send Message",
      endStudy: "End Study",
      updateSettings: "Update Settings",
      formatDevice: "Check In Device",
      forceCheckIn: "Force Check In Device",
      forceActionsFailure: "Force Actions Failure",
      replaceDevice: "Replace Device on Patient",
      convertMctToCem: "Downgrade to CEM Study",
      convertMctWithFullDisclosureToCem: "Downgrade to CEM Study",
      updateFirmware: "Update Firmware",
      resumeStudy: "Resume Study",
      startStudy: "Start Study",
      markStudyAsFailed: "Mark Study as Failed",
    };
  }

  /**
   * Makes request for action types, returns with values and user-friendly readable mappings
   *
   * @see SRS: BR-3136
   * @returns {Promise} Promise object represents the result of get http request
   */
  getTypes() {
    const typesAlreadyGotten = !!this.types;

    if (typesAlreadyGotten) {
      return Promise.resolve(this.types);
    }

    this.types = this.allowedActionTypes.map((type) => {
      const name = this.userFriendlyTypeNames[type.name] || type.name;
      return {
        value: type.name,
        name,
      };
    });

    return Promise.resolve(this.types);
  }

  /**
   * Makes request for actions
   * @param {Object} params parameters to limit search by
   *
   * @see SRS: BR-1238
   * @returns {Promise} Promise object represents the result of get http request
   */
  getAll(params = {}) {
    const url = "/actions";
    return this._httpGet(url, params).then(({data: actionsResponse}) => {
      const actions = actionsResponse.map((action) => new Action(this._$injector, action));
      // Parse the pending version of Update Firmware actions and put it onto those actions
      actions.forEach((action) => {
        const actionType = action.subType || action.name;
        if (actionType === "updateFirmware" && action.data) {
          action.pendingFirmwareVersion = this._parseFirmwareFileName(action.data);
        }
      });
      return actions;
    });
  }

  /**
   * Makes an actions request for latest device settings
   * @param {String} enrollmentId
   * @param {Date} upperBoundTimestamp
   *
   * @returns {Promise<Object>} Promise that resolves to an object with the settings
   */
  getLatestSettings(enrollmentId, upperBoundTimestamp) {
    if (!enrollmentId) {
      return {};
    }

    const params = {};
    if (!["", null, undefined].includes(upperBoundTimestamp)) {
      params.upperBoundTimestamp = upperBoundTimestamp;
    }

    const url = `/actions/latestSettings/${enrollmentId}`;
    return this._httpGet(url, params)
      .then(({data: latestSettings}) => {
        if (latestSettings.studyHours) {
          latestSettings.studyDays = Math.round(latestSettings.studyHours / 24);
        }

        return latestSettings;
      })
      .catch((err) => {
        return {};
      });
  }

  /**
   * Makes request to create a pending action
   * @param {Object} action The action object to create
   * @param {String} actionType The action type to create
   *
   * @see SRS: BR-3136
   * @returns {Promise} Promise object represents the result of get http request
   */
  createPendingAction(action, actionType) {
    const studyTroveActions = ["replaceDevice"];
    let url = `/actions/${actionType}`;
    if (actionType.startsWith("convertMct")) {
      url = "/actions/updateSettings";
      action.subType = actionType;
    } else if (studyTroveActions.includes(actionType)) {
      url = `/studies/${actionType}`;
    }

    return this._httpPost(url, action).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  getUserFriendlyActionData(action) {
    let formattedData = "";
    switch (action.name) {
      case "requestEcgData":
        try {
          const parsedData = JSON.parse(action.originalData);
          formattedData = `${DateTime.fromISO(parsedData.requestedTime).toFormat("yyyy-MM-dd HH:mm ZZZZ")}, ${
            parsedData.preDuration
          } min pre, ${parsedData.postDuration} min post, ${parsedData.comment}`;
        } catch (e) {
          /* Do Nothing */
        }
        break;
      case "requestErrorLogs":
      case "endStudy":
        if (action.comment) {
          formattedData = action.comment;
        } else {
          formattedData = action.originalData;
        }
        break;
      case "sendMessage":
        formattedData = action.comment;
        break;
      case "updateSettings":
      case "convertMctToCem":
      case "convertMctWithFullDisclosureToCem":
        if (action.comment) {
          formattedData = action.comment;
        } else {
          try {
            // Use study note instead of settings values
            formattedData = `${JSON.parse(action.originalData).studyNote}`;
          } catch (e) {
            /* Do Nothing */
          }
        }
        break;
      case "updateFirmware":
      case "formatDevice":
      case "replaceDevice":
      case "startStudy":
      case "forceActionsFailure":
        if (action.comment) {
          formattedData = action.comment;
        } else {
          formattedData = "";
        }
        break;
      default:
        formattedData = action.comment;
    }
    return formattedData;
  }

  /**
   * Parses a firmware version from a path
   * @param {string} path
   * @returns {string}
   */
  _parseFirmwareFileName(path) {
    const nameStart = path.lastIndexOf("/") + 1;
    const totalFileNameCharacters = path.lastIndexOf(".") - nameStart;
    const fileName = path.substr(nameStart, totalFileNameCharacters);
    let version = fileName;

    // if the fileName matches our typical file format, parse the version out of the file name
    if (fileName.match(/^(h3r|hpr)_update_[0-9][0-9][0-9]/g) !== null) {
      version = "";
      if (fileName.charAt(11) !== "0") {
        version += fileName.charAt(11);
      }
      version += `${fileName.charAt(12)}.${fileName.charAt(13)}`;
    }
    return version;
  }

  _httpGet(url, params) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpPost(url, action) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.post(`${baseUrl}${url}`, action, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
